












































import { defineComponent, computed, ref } from '@vue/composition-api';
import TailwindAccodion from '@/app/components/tailwind-accordion/TailwindAccordion.vue';
import TailwindAccodionCard from '@/app/components/tailwind-accordion/TailwindAccordionCard.vue';
import ModelSuggestionDigest from '../components/eventDigests/ModelSuggestionDigest.vue';
import { NotificationSuggestionIcon, NotificationWorkflowIcon } from '../components/icons';
import NotificationDataCheckinJobIcon from '../components/icons/NotificationDataCheckinJobIcon.vue';
import DataCheckinJobDigest from '../components/eventDigests/DataCheckinJobDigest.vue';
import WorkflowDigest from '../components/eventDigests/WorkflowDigest.vue';
import * as R from 'ramda';
import { useAxios } from '@vue-composable/axios';
import { NotificationAPI } from '../api';

export default defineComponent({
    name: 'NotificationsDigest',
    components: { TailwindAccodion, TailwindAccodionCard },
    props: {
        notificationGroups: {
            type: Object,
            required: true,
        },
        createdAt: {
            type: String,
            required: true,
        },
        digestType: {
            type: String,
            required: true,
        },
    },
    setup(props, { root }) {
        const { exec } = useAxios();
        const notificationGroups = ref<any>(null);
        const createdAt = ref<string>('');
        const digestType = ref<string>('');

        if (props.notificationGroups && props.createdAt && props.digestType) {
            notificationGroups.value = props.notificationGroups;
            createdAt.value = props.createdAt;
            digestType.value = props.digestType;
        } else {
            exec(NotificationAPI.fetchOne(Number(root.$route.params.id)))
                .then((res: any) => {
                    notificationGroups.value = res.data?.payload;
                    createdAt.value = res.data?.createdAt;
                    digestType.value = res.data?.eventType;
                })
                .catch((e) => {
                    (root as any).$toastr.e('Unable to load notification', 'Error');
                    throw e;
                });
        }
        const currentPage = ref<number>(1);
        const pageSize = 10;

        const components = {
            ModelSuggestion: {
                header: 'Quick Data Model(s) Update',
                component: ModelSuggestionDigest,
                icon: NotificationSuggestionIcon,
            },
            DataCheckinJob: {
                header:
                    'Quick Data Check-in Job(s) Update: <span class="text-neutral-600">Review successful and failed executions</span>',
                component: DataCheckinJobDigest,
                icon: NotificationDataCheckinJobIcon,
            },
            Workflow: {
                header:
                    'Quick Workflow(s) Update: <span class="text-neutral-600">Review successful and failed executions</span>',
                component: WorkflowDigest,
                icon: NotificationWorkflowIcon,
            },
        };

        const startDate = computed(() => {
            const end = new Date(createdAt.value);
            const start = new Date(createdAt.value);
            const ndays = digestType.value === 'daily_digest' ? 1 : 7;
            start.setTime(end.getTime() - ndays * 24 * 60 * 60 * 1000);
            return start.toLocaleString();
        });
        const endDate = computed(() => {
            return new Date(createdAt.value as string).toLocaleString();
        });

        const filterNotificationGroups = computed(() => {
            if (notificationGroups.value) {
                const filterNot = Object.keys(notificationGroups.value)
                    .filter((key) => key !== 'baseUrl' && key !== 'referenceId' && key !== 'referenceType')
                    .reduce((obj, key) => {
                        return R.assoc(key, notificationGroups.value[key], obj);
                    }, {});
                return filterNot;
            }
            return null;
        });

        return {
            pageSize,
            currentPage,
            TailwindAccodion,
            TailwindAccodionCard,
            components,
            startDate,
            endDate,
            filterNotificationGroups,
        };
    },
});
