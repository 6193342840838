










































































import { defineComponent, computed, ref, watch } from '@vue/composition-api';
import { useAxios } from '@vue-composable/axios';
import { RefreshIcon } from '@vue-hero-icons/outline';
import { Card, ConfirmModal, SvgImage, Pagination } from '@/app/components';
import { NotificationOverview } from '@/modules/notification/components';
import store from '@/app/store';
import { useNotifications } from '@/modules/notification/composable/notifications';
import { NotificationAPI } from '../api';

export default defineComponent({
    name: 'Notifications',
    components: { Card, ConfirmModal, NotificationOverview, SvgImage, Pagination, RefreshIcon },
    setup(props, { root }) {
        const { exec } = useAxios();

        const currentPage = ref<number>(1);
        const pageSize = 10;
        const notificationsElement = ref<HTMLElement>();
        const { markAllAsSeen, showMarkAllAsSeenModal } = useNotifications(root);
        const visibleNotifications = ref<any>([]);
        const toatlNotifications = ref<number>(1);
        const asSeenCount = ref<number>(0);

        const notifications = computed(() => {
            return store.state.notificationEngine.notifications;
        });

        const refresh = computed(() => {
            return store.state.notificationEngine.refresh;
        });

        const fetchNotifications = async () => {
            await exec(NotificationAPI.fetch(currentPage.value, pageSize))
                .then((res) => {
                    visibleNotifications.value = res?.data.notifications;
                    toatlNotifications.value = res?.data.count;
                    asSeenCount.value = res?.data.countUnseen;
                })
                .catch(() => {
                    (root as any).$toastr.e('Getting Notification failed', 'Error');
                });
        };

        const destroy = async (id: number) => {
            await exec(NotificationAPI.delete(id))
                .then(() => {
                    store.commit.notificationEngine.DELETE_NOTIFICATION(id);
                    (root as any).$toastr.removeByType('success');
                    (root as any).$toastr.s('Notification deleted successfully', 'Success');
                })
                .catch(() => {
                    (root as any).$toastr.e('Deleting Notification failed', 'Error');
                });
        };

        const enableMarkAllAsSeen = computed(() => {
            return asSeenCount.value !== 0;
        });

        const markAsSeen = async (id: number) => {
            await store.dispatch.notificationEngine.changeSeenAt(id);
            fetchNotifications();
        };

        const scrollToTop = () => {
            if (notificationsElement.value) {
                notificationsElement.value.scrollIntoView({ behavior: 'smooth' });
            }
        };

        const refreshPage = () => {
            root.$router.go(0);
        };

        watch(
            () => currentPage.value,
            async () => {
                fetchNotifications();
            },
        );

        fetchNotifications();

        return {
            destroy,
            notifications,
            pageSize,
            enableMarkAllAsSeen,
            markAsSeen,
            markAllAsSeen,
            showMarkAllAsSeenModal,
            visibleNotifications,
            refresh,
            toatlNotifications,
            currentPage,
            scrollToTop,
            notificationsElement,
            refreshPage,
        };
    },
});
